<template>
  <div class="pl-order-wrap">
    <div class="module-title">我的订单</div>
    <div class="pl-search-box pl-flex">
      <span>订单状态</span>
      <el-select v-model="param.status" placeholder="请选则订单状态" @change="changeList">
        <el-option
            v-for="(item, index) in optionList" :key="index" :label="item"
            :value="index === 0 ? '' : index"></el-option>
        <el-option label="已取消" value="0"></el-option>
      </el-select>
      <span>下单时间</span>
      <el-date-picker
          style="margin-left: 10px"
          v-model="orderTime" type="datetimerange" range-separator="-" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
      </el-date-picker>

      <el-select v-model="searchKey" style="margin-left: 10px">
        <el-option
            v-for="(item, index) in optionKey" :key="index" :label="item.label"
            :value="item.key"></el-option>
      </el-select>
      <!-- <span>关键词</span> -->
      <el-input style="margin-left: 10px" class="pl-input" v-model="searchVal" placeholder="请输入关键词"></el-input>
      <el-button
          type="primary" @click="searchFun" :loading="loading"
          style="background: #d9262c;border: #d9262c; margin-left: 10px">搜索
      </el-button>
    </div>
    <div class="pl-table-box">

      <div class="pl-table-th-box pl-flex" style="background: #ececec;">
        <div class="pl-table-th pl-detail">商品详情</div>
        <div class="pl-table-th">下单人</div>
        <div class="pl-table-th">订单状态</div>
        <div class="pl-table-th">购买数量</div>
        <div class="pl-table-th">订单总金额</div>
        <div class="pl-table-th">支付方式</div>
        <div class="pl-table-th">操作</div>
      </div>
      <div class="pl-table-row-box" v-for="(item, index) in tableData" :key="index">
        <div class="pl-row-th-box">
          <span>{{ item.created_at }}</span>
          <span>{{ item.sn }}</span>
        </div>
        <div class="pl-table-row pl-flex" v-for="(info) in item.order_goods" :key="info.id">
          <div class="pl-table-td pl-detail">
            <img :src="info.goods_cover" class="pl-avator">
            <div class="pl-shopping-detail">
              <div>
                <p @click="$router.push(`/goods/detail?id=${info.goods_id}`)" class="pl-title">
                  {{ info.goods_name }}</p>
                <p class="num">x{{ info.quantity }}</p>
              </div>
              <span class="pl-xh">{{ info.production_model }}</span>
            </div>
          </div>
          <div class="pl-table-td">
            {{ item.user.name }}
          </div>
          <div class="pl-table-td">
            {{ statusOptions[item.status] }}
          </div>
          <div class="pl-table-td">
            {{ info.quantity }}
          </div>
          <div class="pl-table-td">
            {{ item.total_amount }}
          </div>
          <div class="pl-table-td">
            {{ options[item.pay_type] }}
          </div>
          <div class="pl-table-td pl-operation">
            <p v-if="item.status == 1" class="pointer" @click="goPay(item)">去支付</p>
            <p v-if="item.status == 1" @click="cancelOrder(item)" class="pointer">取消订单</p>
            <p v-if="item.status == 2">待发货</p>
            <p v-if="item.status == 3 || item.status == 2" @click="applySales(item)" class="pointer">申请售后</p>
            <p v-if="item.status == 3" @click="confirmOrder(item)" class="pointer">确认收货</p>
            <p v-if="item.status == 5">售后完成</p>
            <p v-if="item.status == 6">已完成</p>
            <p v-if="item.status == 0">已取消</p>
            <p @click="orderDetail(item)" class="pointer">查看详情</p>

          </div>
        </div>
      </div>
    </div>
    <el-empty description="暂无数据" v-if="tableData.length === 0"></el-empty>
    <div class="pl-pagination">
      <el-pagination
          background layout="prev, pager, next" :page-size="param.per_page" :total="param.total"
          @current-change="changePage">
      </el-pagination>
    </div>
    <!-- 取消订单 -->
    <el-dialog title="请选择取消订单的原因" :visible.sync="dialogVisible" width="20%">
      <div class="pl-radio-box">
        <el-radio v-model="cancel_desc" label="拍错数量">拍错数量</el-radio>
        <br>
        <el-radio v-model="cancel_desc" label="不想要了">不想要了</el-radio>
        <br>
        <el-radio v-model="cancel_desc" label="其他">其他</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmCancelOrder">确 定</el-button>
            </span>
    </el-dialog>
    <PayQr :id="wxId" :wxurl="wxUrl" :show.sync="showWxPay"/>
  </div>
</template>
<script>
import {cancelOrder, confirmReceipt, orderList} from '@/api/order.js'
import {alipay, wxpay} from '@/api/pay.js'
import PayQr from '@/views/user/shoppingCart/pay.vue'

export default {
  components: {
    PayQr
  },
  data() {
    return {
      alipay,
      selectPay: '',
      searchKey: 'sn',
      searchVal: '',
      orderTime: [],
      dialogVisible: false,//取消订单弹窗
      options: ['全部', '微信', '支付宝', '银联'],
      statusOptions: ['已取消', '待支付', '待发货', '待收货', '售后中', '售后完成', '已完成'],
      optionList: ['全部', '待支付', '待发货', '待收货', '售后中', '售后完成', '已完成'],
      optionKey: [{label: '订单号', key: 'sn'}, {label: '商品名称', key: 'goods_name'}, {
        label: '商品型号',
        key: 'production_model'
      }],
      param: {
        start_at: '',
        end_at: '',
        status: '',
        page: 1,
        per_page: 10,
        total: 0
      },
      tableData: [],
      cancelId: '',//取消订单id
      cancel_desc: '拍错数量',//取消理由
      wxId: '',
      wxUrl: '',
      showWxPay: false,
      loading: false
    }
  },
  methods: {
    changeList() {
      this.orderList()
    },
    goPage(path) {
      this.$router.push(path)
    },
    searchFun() {
      this.loading = true
      this.param.page = 1
      this.orderList()
    },
    orderList() {
      let param = this.param
      param.start_at = this.orderTime && this.orderTime[0] || ''
      param.end_at = this.orderTime && this.orderTime[1] || ''
      param[this.searchKey] = this.searchVal
      orderList(param).then(({total, data}) => {
        this.param.total = total || 0
        this.tableData = data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    // 查看详情
    orderDetail(data) {
      this.$router.push({path: '/user/order/detail', query: {id: data.id}})
    },
    // 确认收货
    confirmOrder(data) {
      confirmReceipt(data.id).then(() => {
        this.$message.success('确认收货成功')
        this.orderList()
      })
      console.log(data)
    },
    // 申请售后
    applySales(data) {
      this.$router.push(`/user/order/sales?id=${data.id}`)
      console.log(data)
    },
    // 取消订单
    cancelOrder(data) {
      this.dialogVisible = true
      console.log(data)
      this.cancelId = data.id

    },
    confirmCancelOrder() {
      cancelOrder(this.cancelId, {cancel_desc: this.cancel_desc}).then(() => {
        this.dialogVisible = false
        this.$message.success('取消成功')
        this.orderList()
      })
    },
    // 去支付
    goPay(data) {
      switch (+data.pay_type) {
        case 1:
          wxpay({order_id: data.id}).then(({code_url}) => {
            this.wxId = data.id
            this.wxUrl = code_url
            this.showWxPay = true
          })
          break
        case 2:
          this.alipay += data.id
          window.open(this.alipay, '_blank')
          break
        case 3:
          this.$router.push('/user/shopping/bank?id=' + data.id)
          break
      }
      console.log(data)

    },
    changePage(v) {
      this.param.page = v
      this.orderList()
    }
  },
  created() {
    this.orderList()
  }
}
</script>
<style lang="scss" scoped>
.pl-order-wrap {
  background: #FFFFFF;
  padding: 10px;
  border-radius: 4px;

  .pl-search-box {
    > span {
      display: inline-block;
      width: 100px;
      box-sizing: border-box;
      text-align: center;

    }

    .pl-input {
      width: 200px;
    }
  }

  .pl-table-box {
    .pl-table-th-box {
      width: 100%;
      height: 60px;
      background: #E4E6EE;
      text-align: center;
      //border-radius: 12px;
      margin: 30px 0 20px;
      padding: 0 20px;

      .pl-table-th {
        width: 100px;
        font-size: 18px;
      }

      .pl-detail {
        width: 300px;
      }
    }

    .pl-table-row-box {
      .pl-table-row {
        padding: 20px;
        background: #fff;
      }

      .pl-table-td {
        width: 100px;
        text-align: center;
      }

      .pl-detail {
        width: 300px;
        display: flex;
        justify-content: flex-start;

        img {
          width: 97px;
          height: 97px;
          margin-right: 20px;
        }

        .pl-shopping-detail {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          color: #cacbd0;
          text-align: left;

          > div {
            width: 100%;
          }

          .pl-title {
            font-size: 18px;
            color: #1e1e1e;
            cursor: pointer;
            &:hover {
              color: #D9262C;
            }
          }

          .num {
            margin-top: 8px;
          }

          .pl-xh {
            display: flex;
            align-items: self-end;
          }
        }
      }

      .pl-operation {
        p {
          cursor: pointer;
          margin-top: 8px;
        }
      }

      .pl-row-th-box {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background: #ececec;
        font-size: 16px;

        > span {
          padding: 0 20px;
        }
      }
    }
  }

  .pl-radio-box {
    ::v-deep.el-radio {
      margin: 15px 0
    }
  }
}

::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #d9262c;
    color: #FFF;
  }

  .el-button--text {
    color: red;
  }

  .el-range-editor.is-active,
  .el-range-editor.is-active:hover,
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #d9262c;
  }

  .el-select-dropdown__item.selected {
    color: #d9262c;
  }

  .el-select .el-input__inner {
    border-color: #d9262c;
  }

  .el-input__inner {
    border-color: #d9262c;
  }
}
</style>
